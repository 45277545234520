<template>
  <div
    v-click-outside="close"
    :class="{ active: active, open: search && active && getSearchReasult }"
    class="fast-search"
  >
    <Spinner v-if="getSearchLoading" class="spinner" />
    <font-awesome-icon v-else class="icon" icon="search" />
    <input
      v-model="search"
      @focus="active = true"
      class="search-input"
      type="text"
      :placeholder="$t('search')"
    />
    <div v-if="search && active && getSearchReasult" class="global-search">
      <div class="global-search-tabs">
        <div class="global-search-tab-title">
          {{ $t('projet') }}
        </div>
      </div>
      <div class="tab-content">
        <p v-if="!getSearchReasult.length" class="no-result">
          {{ $t('aucun') }} {{ $t('aucunResultat') }} "{{ search }}"
        </p>
        <template v-else>
          <ul class="search-results">
            <li
              @click="close"
              v-for="(item, index) in getSearchReasult"
              :key="`all-${index}-${item.id}`"
            >
              <SearchResult :data="item" @click="close" :search="search" />
            </li>
          </ul>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import SearchResult from './SearchResult'

export default {
  components: {
    SearchResult,
    Spinner: () => import('@/components/ui/Spinner.vue')
  },
  props: {},
  data() {
    return {
      active: false,
      search: ''
    }
  },
  methods: {
    ...mapActions(['globalSearch']),
    close() {
      this.active = false
    }
  },
  computed: {
    ...mapGetters(['getSearchReasult', 'getSearchLoading']),
    getProjects: function() {
      if (this.getSearchReasult) {
        return this.getSearchReasult.filter(item => item.type == 'projet')
      }
      return []
    },
    getContact: function() {
      if (this.getSearchReasult) {
        return this.getSearchReasult.filter(item => item.type == 'contact')
      }
      return []
    },
    getFiles: function() {
      if (this.getSearchReasult) {
        return this.getSearchReasult.filter(item => item.type == 'file')
      }
      return []
    }
  },
  watch: {
    search: debounce(function(query) {
      if (query && query.length > 1) {
        this.globalSearch({
          type_id: localStorage.getItem('typeProject'),
          fullName: query
        })
      }
    }, 400)
  }
}
</script>

<style lang="scss">
.fast-search {
  position: relative;
  padding: 6px;
  color: #26292c;

  .icon {
    position: absolute;
    font-size: 18px;
    top: 15px;
    left: 14px;
    height: 16px;
    color: #7c7c7c6b;
  }

  .spinner {
    position: absolute;
    top: 14px;
    left: 12px;
    width: 20px;
  }

  .search-input {
    border: none;
    height: 36px;
    // width: 160px;
    width: 100%;
    padding: 3px 3px 3px 33px;
    border-radius: 3px;
    box-sizing: border-box;
    box-shadow: none;
    opacity: 1;
    background: rgb(0 0 0 / 2%);
    border-radius: 5px;

    // &:hover {
    //   opacity: 0.65;
    //   //   background-color: #b9babb;
    // }
    &:active,
    &:focus,
    &:focus-visible {
      // background-color: #fff;
      //   box-shadow: 0 0 0 1px #b9babb;
      border: none;
      outline: none;
    }
  }

  .global-search {
    position: absolute;
    background-color: #f6f6f6;
    z-index: 9999;
    top: 100%;
    right: 0;
    border: 1px solid #bdbdbd;
    padding: 0 0 8px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15), 0 3px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0 4px 4px 4px;
    .global-search-tabs {
      display: block;
      position: relative;
      overflow: hidden;
      width: 320px;
      max-height: calc(100vh - 48px);
      background-color: #f6f6f6;
      border-bottom: 1px solid #b9babb;
      padding: 8px 8px 10px;
      .global-search-tab-title {
        position: relative;
        font-size: 12px;
        color: #ffffff;
        background-color: #5c2dd3;
        border: 1px solid #5c2dd3;
        border-radius: 4px;
        padding: 0px 10px;
        margin: 2px 4px;
        width: max-content;
        cursor: pointer;
        user-select: none;
        transition: all 0.4s ease;

        .counter {
          color: #858585;
          margin-left: 5px;
        }

        &.active {
          color: #5e5e5e;
          background-color: #fff;
          .counter {
            color: #2dabe2;
          }
        }
      }
    }
    .tab-content {
      display: block;
      position: relative;
      max-height: calc(100vh - 217px);
      overflow: auto;
      .search-results {
        list-style: none;
        display: block;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          margin: 0;
          padding: 0;
          position: relative;
          word-wrap: break-word;
          overflow: hidden;
        }
      }

      .no-result {
        padding: 10px;
        margin: 0;
      }
    }
    .tab-content::-webkit-scrollbar {
      width: 7px;
      height: 5px;
    }
    .tab-content::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f0eef8;
      border-radius: 4px;
      background-color: rgba(238, 238, 238, 1) !important;
    }
    .tab-content::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(189, 189, 189, 1);
    }
  }

  //   &.open {
  //     background-color: #ebebeb;
  //   }

  &.active {
    .icon {
      color: #5c2dd3;
    }
  }
}
</style>
